<template>
  <v-autocomplete
    @update:search-input="isTyping = true" 
    @input="chosenPerson"
    @click:clear="clearSearch"
    v-model="model"
    :search-input.sync="searchQuery" 
    label="Search Buyer"
    :items="searchResult"
    :item-text="getBuyerInfo"
    item-value="OID"
    :hide-no-data="hideNoData"
    :no-data-text="noDataText"
    :loading="loading"
    prepend-icon="mdi-account"
    :menu-props="{ offsetY: true }"
    clearable
    return-object
    hint="At least 5 characters"
    :color="color"
  ></v-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce'
import { API } from '@aws-amplify/api'

export default {
  name: 'search-buyer',
  props: {
    color: {
      type: String,
      default: 'blue'
    }
  },
  data() {
    return {
      model: null,
      searchQuery: null,
      isTyping: false,
      searchResult: [],
      loading: false,
      hideNoData: true,
      noDataText: '',
    }
  },
  watch: {
    searchQuery: debounce(function() {
      this.isTyping = false;
    }, 1000),
    isTyping: function(value) {
      if (!value && this.searchQuery && this.searchQuery.length > 5) {
        this.searchPerson(this.searchQuery)
        this.loading = true
      }
    }
  },
  methods: {
    clearSearch() {
      this.$emit('clearSearch')
    },
    getBuyerInfo(obj) {
      return `${obj.Name} - ${obj.Email}`
    },
    chosenPerson() {
      const selectedPersons = this.model
      this.$emit('selectedBuyer', selectedPersons)
      this.model = null
    },
    async searchPerson(query) {
      this.loading = true
      this.hideNoData = true
      
      try {
        const apiName = 'negAppRestApi';
        const path = '/proxy/get';
        const myInit = {
          headers: {
            token: this.$store.state.user.LoginToken
          },
          queryStringParameters: {
            endpoint: `/${this.$store.state.user.ClientName}/search/buyer`,
            name: query
          }
        };
        
        const response = await API.get(apiName, path, myInit);
        const buyerData = response.data; // Get raw response data
        
        if(buyerData && buyerData.Count > 0) {
          this.searchResult = buyerData.Data;
          this.hideNoData = true;
          console.log('Buyer search successful');
        } else {
          this.searchResult = [];
          this.hideNoData = false;
          this.noDataText = "No buyer found with this name";
        }
      } catch (error) {
        console.error('Error searching for buyer:', error);
        this.searchResult = [];
        this.hideNoData = false;
        this.noDataText = "An error has occurred in the system. Please try again.";
        this.$emit('searchError', error);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>
<template>
  <div class="dashboard" v-if="sessionData">
    <v-container class="pt-5" fluid>
      <v-progress-linear
        color="secondary"
        v-if="$store.state.fetchinAppointments"
        indeterminate
      ></v-progress-linear>
      <v-row>
        <v-col
          class="pt-1"
          cols="12"
          :md="showMobileAgenda ? 7 : 12"
          :lg="showMobileAgenda ? 8 : 12"
        >
          <Calendar
            @toggleTimeline="showMobileAgenda = !showMobileAgenda"
            @newFocus="newCalendarFocus"
            :newAgendaDate="newAgendaDate"
            class="d-none d-md-flex"
          />
        </v-col>
        <v-col class="mt-3 mt-lg-1 pt-1 px-0 px-md-1" cols="12" md="5" lg="4">
          <MobileAgenda
            v-if="showMobileAgenda"
            :newFocusDate="newFocusDate"
            @newDate="setAgendaDate"
          />
        </v-col>
      </v-row>

      <v-snackbar
        transition="scroll-y-reverse-transition"
        v-model="unreadEmailsSnackbar"
        right
        color="#eb5c37"
      >
        <span v-if="$store.state.staffEmails">
          You have {{ $store.state.staffEmails.staff.data.Data.Unread }} unread
          <span v-if="$store.state.staffEmails.staff.data.Data.Unread === 1">email.</span>
          <span v-else>emails.</span>
        </span>

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="unreadEmailsSnackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MobileAgenda from "@/components/Agendas/MobileAgenda";
import Calendar from "@/components/TheCalendar";

export default {
  name: "dashboard",
  components: {
    MobileAgenda,
    Calendar,
  },
  data: () => ({
    dialog: false,
    viewAppointments: true,
    newFocusDate: "",
    newAgendaDate: "",
    unreadEmailsSnackbar: false,
    showMobileAgenda: true,
  }),
  created() {
  this.$store.commit("EXPIRE_LOGIN");
  if (this.$store.state.user !== '') {
    // Check if staff emails are loaded
    if (!this.$store.state.staffEmails.staff.data.Data.length) {
      this.$store.dispatch("getAllStaffEmails")
        .then(() => this.alertUnreadMessages());
    }
    
    // Check if branch emails are loaded
    if (!this.$store.state.branchEmails.branch.data.Data.length) {
      this.$store.dispatch("getAllBranchEmails");
    }
    
    // Check if branch SMS messages are loaded
    if (!this.$store.state.branchSms.Data.length) {
      this.$store.dispatch("Messages/getBranchSms");
    }
    
    // Check if online applications are loaded
    if (!this.$store.state.onlineApplications.staff.data.Data.length) {
      this.$store.dispatch("getAllOnlineApplications");
    }
    
    // Check if login registrations are loaded
    if (!this.$store.state.loginRegistrations.staff.data.Data.length) {
      this.$store.dispatch("getAllLoginRegistrations");
    }
    
    // Check if sent messages are loaded
    if (!this.$store.state.sentMessages.Data.length) {
      this.$store.dispatch("Messages/getSentMessages");
    }
    
    // Check if sales properties are loaded
    if (!this.$store.state.salesProperties.staff.data.Data.length) {
      this.$store.dispatch("getAllSalesProperties");
    }
    
    // Check if lettings properties are loaded
    if (!this.$store.state.lettingsProperties.staff.data.Data.length) {
      this.$store.dispatch("getAllLettingsProperties");
    }
    
    // Check if SMS accounts are loaded
    if (!this.$store.state.loggedInUserSmsAccounts.Data.length) {
      this.$store.dispatch("Messages/getLoggedInUserSmsAccount");
    }
    
    // Check if email accounts are loaded
    if (!this.$store.state.loggedInUserEmailAccounts.Data.length) {
      this.$store.dispatch("Messages/getLoggedInUserEmailAccount");
    }
    
    // Check if all diaries are loaded
    if (!this.$store.state.Calendar?.allDiaries) {
      this.$store.dispatch("Calendar/listAllDiaries");
    }
    } else {
      this.$router.push('/');
    }
  },
  methods: {
    newCalendarFocus(val) {
      this.newFocusDate = val;
    },
    setAgendaDate(val) {
      this.newAgendaDate = val;
    },
    alertUnreadMessages() {
      if (this.$store.state.staffEmails.staff.data.Data.Unread >= 1) {
        this.unreadEmailsSnackbar = true;
      }
    },
  },
  computed: {
    ...mapState(["sessionData", "appointments"]),
  },
};
</script>

<style lang="css">
.v-sheet.v-snack__wrapper {
  right: 0px !important;
  bottom: 180px !important;
}

@media only screen and (min-width: 768px) {
  .v-sheet.v-snack__wrapper {
    right: 10px !important;
    bottom: 180px !important;
  }
}
</style>
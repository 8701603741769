import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import '@aws-amplify/ui-vue';
import aws_exports from './aws-exports';
import { Amplify, API, Auth, Storage } from 'aws-amplify';
Amplify.register(API)
Amplify.register(Storage)
Amplify.register(Auth)
/* Register the services before configure */

Amplify.configure(aws_exports);

Vue.config.productionTip = false

Vue.filter('filterPriceGlobal', function (val) {
  if (!val) return ''
  return val.toLocaleString('en-UK', { style: 'currency', currency: 'GBP' })
})

new Vue({
  router,
  store,
  vuetify,
  created() {
    const userString = localStorage.getItem('user')
    if (userString) {
      const userData = JSON.parse(userString)
      this.$store.commit('SET_USER_DATA', userData)
    }
  },
  render: (h) => h(App),
}).$mount('#app')
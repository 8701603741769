import Vue from 'vue'
import Vuex from 'vuex'
import {API} from 'aws-amplify'
//modules
import Messages from './modules/Messages'
import OffersApplications from './modules/OffersApplications'
import Calendar from './modules/Calendar'

import {authAgentOSSignIn, authAgentOSSignOut} from './agentosAuth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Messages,
    OffersApplications,
    Calendar
  },
  state: {
    user: '',
    sessionData: '',
    appointmentTypes: [],
    appointments: [],
    today: new Date(),
    fetchinAppointments: false,
    myLettingProperties: '',
    propertyTypes: '',
    selectedPropertyType: '',
    date: new Date(),
    date2: new Date(),
    config: '',
    selectedColleagueDiary: '',
  
    staffEmails: {
      staff: {
        data: {
          Data: [],
          Total: 0,
          Unread: 0,
          SentMessages: []
        }
      },
      completed: {
        data: {
          Data: [],
          Total: 0
        }
      }
    },
    branchEmails: {
      branch: {
        data: {
          Data: [],
          Total: 0,
          Unread: 0,
          SentMessages: []
        }
      },
      completed: {
        data: {
          Data: [],
          Total: 0
        }
      }
    },
    branchSms: {
      Data: [],
      Unread: 0,
      Total: 0,
      SentMessages: []
    },
    sentMessages: {
      Data: [],
      Total: 0
    },
    onlineApplications: {
      staff: {
        data: {
          Data: [],
          Count: 0
        }
      },
      staffCompleted: {
        data: {
          Data: [],
          Count: 0
        }
      },
      branch: {
        data: {
          Data: [],
          Count: 0
        }
      },
      branchCompleted: {
        data: {
          Data: [],
          Count: 0
        }
      }
    },
    loginRegistrations: {
      staff: {
        data: {
          Data: [],
          Count: 0
        }
      },
      staffCompleted: {
        data: {
          Data: [],
          Count: 0
        }
      },
      branch: {
        data: {
          Data: [],
          Count: 0
        }
      },
      branchCompleted: {
        data: {
          Data: [],
          Count: 0
        }
      }
    },
    salesProperties: {
      staff: {
        data: {
          Data: [],
          Count: 0
        }
      },
      staffCompleted: {
        data: {
          Data: [],
          Count: 0
        }
      },
      branch: {
        data: {
          Data: [],
          Count: 0
        }
      },
      branchCompleted: {
        data: {
          Data: [],
          Count: 0
        }
      }
    },
    lettingsProperties: {
      staff: {
        data: {
          Data: [],
          Count: 0
        }
      },
      staffCompleted: {
        data: {
          Data: [],
          Count: 0
        }
      },
      branch: {
        data: {
          Data: [],
          Count: 0
        }
      },
      branchCompleted: {
        data: {
          Data: [],
          Count: 0
        }
      }
    },
    loggedInUserEmailAccounts: {
      Data: []
    },
    loggedInUserSmsAccounts: {
      Data: []
    }
  },
  
mutations: {
  SET_USER_DATA(state, userData) {
    state.user = userData;
    state.user["loginTime"] = Date.now();
    localStorage.setItem('user', JSON.stringify(userData));
    state.config = {
      headers: {
        token: state.user.LoginToken
      }
    };
    authAgentOSSignIn(userData.UserOID, userData.UserDisplayName, userData.UserToken);
  },
  
  SET_SESSION_DATA(state, data) {
    state.sessionData = data;
  },
  
  EXPIRE_LOGIN(state) {
    const then = new Date(state.user.loginTime);
    const now = new Date();
    const msBetweenDates = Math.abs(then.getTime() - now.getTime());
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
    if (hoursBetweenDates > 23) {
      state.user = '';
      localStorage.setItem('user', '');
    }
  },
  
  CLEAR_USER_DATA(state) {
    state.user = '';
    state.sessionData = '';
    state.appointmentTypes = [];
    state.appointments = [];
    state.myLettingProperties = '';
    state.propertyTypes = '';
    localStorage.removeItem('user');
    state.config = '';
    
    state.loggedInUserEmailAccounts = { Data: [] };
    state.loggedInUserSmsAccounts = { Data: [] };
    state.staffEmails = {
      staff: { 
        data: { 
          Data: [], 
          Total: 0, 
          Unread: 0, 
          SentMessages: [] 
        } 
      },
      completed: { 
        data: { 
          Data: [], 
          Total: 0 
        } 
      }
    };
    state.branchEmails = {
      branch: { 
        data: { 
          Data: [], 
          Total: 0, 
          Unread: 0, 
          SentMessages: [] 
        } 
      },
      completed: { 
        data: { 
          Data: [], 
          Total: 0 
        } 
      }
    };
    state.branchSms = { Data: [], Unread: 0, Total: 0, SentMessages: [] };
    state.sentMessages = { Data: [], Total: 0 };
    state.onlineApplications = {
      staff: { data: { Data: [], Count: 0 } },
      staffCompleted: { data: { Data: [], Count: 0 } },
      branch: { data: { Data: [], Count: 0 } },
      branchCompleted: { data: { Data: [], Count: 0 } }
    };
    state.loginRegistrations = {
      staff: { data: { Data: [], Count: 0 } },
      staffCompleted: { data: { Data: [], Count: 0 } },
      branch: { data: { Data: [], Count: 0 } },
      branchCompleted: { data: { Data: [], Count: 0 } }
    };
    state.salesProperties = {
      staff: { data: { Data: [], Count: 0 } },
      staffCompleted: { data: { Data: [], Count: 0 } },
      branch: { data: { Data: [], Count: 0 } },
      branchCompleted: { data: { Data: [], Count: 0 } }
    };
    state.lettingsProperties = {
      staff: { data: { Data: [], Count: 0 } },
      staffCompleted: { data: { Data: [], Count: 0 } },
      branch: { data: { Data: [], Count: 0 } },
      branchCompleted: { data: { Data: [], Count: 0 } }
    };
    state.selectedColleagueDiary = '';
    state.Calendar.allDiaries = '';
    
    authAgentOSSignOut();
  },
  
  SET_APPOINTMENTS(state, data) {
    state.appointments = data;
  },
  
  SET_COLLEAGUES_APPOINTMENTS(state, data) {
    state.appointments.push(...data);
  },
  
  SET_COLLEAGUES_DIARY(state, data) {
    state.selectedColleagueDiary = data;
  },
  
  CLEAR_COLLEAGUE_DIARY(state) {
    state.selectedColleagueDiary = '';
  },
  
  SET_APPOINTMENT_TYPES(state, data) {
    state.appointmentTypes = data;
  },
  
  SET_MY_LETTING_PROPERTIES(state, data) {
    state.myLettingProperties = data;
  },
  
  SET_PROPERTY_TYPES(state, data) {
    state.propertyTypes = data;
  },
  
  SET_PROPERTY_TYPE(state, data) {
    state.selectedPropertyType = data;
  },
  
  SET_LOGGED_IN_USER_EMAIL_ACCOUNTS(state, data) {
    state.loggedInUserEmailAccounts = {
      Data: Array.isArray(data) ? data : []
    };
  },
  
  SET_LOGGED_IN_USER_SMS_ACCOUNTS(state, data) {
    state.loggedInUserSmsAccounts = {
      Data: Array.isArray(data) ? data : []
    };
  },
  
  SET_ONLINE_APPLICATIONS(state, { staff, staffCompleted, branch, branchCompleted }) {
    state.onlineApplications = {
      staff: staff ?? { 
        data: { Data: [], Count: 0 } 
      },
      staffCompleted: staffCompleted ?? { 
        data: { Data: [], Count: 0 } 
      },
      branch: branch ?? { 
        data: { Data: [], Count: 0 } 
      },
      branchCompleted: branchCompleted ?? { 
        data: { Data: [], Count: 0 } 
      }
    };
  },
  
  SET_SALES_PROPERTIES(state, { staff, staffCompleted, branch, branchCompleted }) {
    state.salesProperties = {
      staff: staff ?? { 
        data: { Data: [], Count: 0 } 
      },
      staffCompleted: staffCompleted ?? { 
        data: { Data: [], Count: 0 } 
      },
      branch: branch ?? { 
        data: { Data: [], Count: 0 } 
      },
      branchCompleted: branchCompleted ?? { 
        data: { Data: [], Count: 0 } 
      }
    };
  },
  
  SET_STAFF_EMAILS(state, { staff, completed }) {
    state.staffEmails = {
      staff: staff ?? {
        data: {
          Data: [],
          Total: 0,
          Unread: 0,
          SentMessages: []
        }
      },
      completed: completed ?? {
        data: {
          Data: [],
          Total: 0
        }
      }
    };
    console.log(state.staffEmails);
  },
  
  SET_BRANCH_EMAILS(state, { branch, completed }) {
    state.branchEmails = {
      branch: branch ?? {
        data: {
          Data: [],
          Total: 0,
          Unread: 0,
          SentMessages: []
        }
      },
      completed: completed ?? {
        data: {
          Data: [],
          Total: 0
        }
      }
    };
  },
  
  SET_BRANCH_SMS(state, data) {
    state.branchSms = data ?? {
      Data: [],
      Unread: 0,
      Total: 0,
      SentMessages: []
    };
  },
  
  SET_SENT_MESSAGES(state, data) {
    state.sentMessages = data ?? {
      Data: [],
      Total: 0
    };
  },
  
  SET_LOGIN_REGISTRATIONS(state, { staff, staffCompleted, branch, branchCompleted }) {
    state.loginRegistrations = {
      staff: staff ?? { 
        data: { Data: [], Count: 0 } 
      },
      staffCompleted: staffCompleted ?? { 
        data: { Data: [], Count: 0 } 
      },
      branch: branch ?? { 
        data: { Data: [], Count: 0 } 
      },
      branchCompleted: branchCompleted ?? { 
        data: { Data: [], Count: 0 } 
      }
    };
  },
  
  SET_LETTINGS_PROPERTIES(state, { staff, staffCompleted, branch, branchCompleted }) {
    state.lettingsProperties = {
      staff: staff ?? { 
        data: { Data: [], Count: 0 } 
      },
      staffCompleted: staffCompleted ?? { 
        data: { Data: [], Count: 0 } 
      },
      branch: branch ?? { 
        data: { Data: [], Count: 0 } 
      },
      branchCompleted: branchCompleted ?? { 
        data: { Data: [], Count: 0 } 
      }
    };
  }
},
  actions: {
    login({ commit }, credentials) {
      const apiName = 'negAppRestApi';
      const path = '/auth/login';
      const myInit = {
        body: {
          Username: credentials.username,
          Password: credentials.password
        }
      };
      return API.post(apiName, path, myInit)
        .then(({ data }) => {
          commit('SET_USER_DATA', data)
        })
    },
    
    loginAgentOSSessionID({commit, dispatch}, credentials) {
      const apiName = 'negAppRestApi';
      const path = '/auth/loginFromOS';
      const myInit = {
        body: {
          ShortName: credentials.shortName,
          SessionID: credentials.sessionID
        }
      };
      return API.post(apiName, path, myInit)
        .then(({data}) => {
          commit('SET_USER_DATA', data)
          return dispatch('sessionStatus')
        })
    },
    sessionStatus({ rootState, dispatch, commit }) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/get';
      const myInit = {
        headers: rootState.config.headers,
        queryStringParameters: {
          endpoint: `/${rootState.user.ClientName}/session/status`
        }
      };
      return API.get(apiName, path, myInit)
        .then(({ data }) => {
          commit('SET_SESSION_DATA', data)
        })
        .catch(() => {
          // If session check fails, trigger logout from root store
          dispatch('logout', null, { root: true })
        })
    },
    logout({ commit, state }) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/delete';
      const myInit = {
        headers: state.config.headers,
        queryStringParameters: {
          endpoint: `/${state.user.ClientName}/session/logout`
        }
      };
      return API.del(apiName, path, myInit)
        .then(() => {
          commit('CLEAR_USER_DATA')
        })
    },
    fetchAppointmentTypes({ commit, state }) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/get';
      const myInit = {
        headers: state.config.headers,
        queryStringParameters: {
          endpoint: `/${state.user.ClientName}/appointments/types/0/100`
        }
      };
      return API.get(apiName, path, myInit)
        .then(({ data }) => {
          commit('SET_APPOINTMENT_TYPES', data.Data)
        })
    },
    
    fetchAppointments({ state, commit }) {
      let date = state.date;
      date.setDate(date.getDate() - 30);
      let pastDate = date.toISOString().substr(0, 10);
    
      let date2 = state.date2;
      date2.setDate(date2.getDate() + 30);
      let futureDate = date2.toISOString().substr(0, 10);
    
      state.fetchinAppointments = true;
    
      const apiName = 'negAppRestApi';
      const path = '/proxy/get';
      const myInit = {
        headers: state.config.headers,
        queryStringParameters: {
          endpoint: `/${state.user.ClientName}/diary/appointments`,
          startDate: pastDate,
          endDate: futureDate
        }
      };
      return API.get(apiName, path, myInit)
        .then(({ data }) => {
          state.fetchinAppointments = false;
          let newArray = data.map((obj) => {
            return {
              ...obj,
              end: obj.End,
              start: obj.Start,
              name: obj.Subject,
              myCalendar: true
            }
          });
          commit('SET_APPOINTMENTS', newArray)
        })
    },
    
    fetchAppointmentWithDates({state, commit}, filterDates) {
      let pastDate = filterDates.start;
      let futureDate = filterDates.end;
      state.fetchinAppointments = true;
    
      const apiName = 'negAppRestApi';
      const path = '/proxy/get';
      const myInit = {
        headers: state.config.headers,
        queryStringParameters: {
          endpoint: `/${state.user.ClientName}/diary/appointments`,
          startDate: pastDate,
          endDate: futureDate
        }
      };
      return API.get(apiName, path, myInit)
        .then(({ data }) => {
          state.fetchinAppointments = false;
          let newArray = data.map((obj) => {
            return {
              ...obj,
              end: obj.End,
              start: obj.Start,
              name: obj.Subject,
              myCalendar: true
            }
          });
          commit('SET_APPOINTMENTS', newArray)
        })
    },
    
    createAppointment({ state }, appointment) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/post';
      const myInit = {
        headers: state.config.headers,
        queryStringParameters: {
          endpoint: `/${state.user.ClientName}/appointments/appointment/create`
        },
        body: appointment
      };
      return API.post(apiName, path, myInit)
    },
    
    deleteAppointment({ state }, id) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/delete';
      const myInit = {
        headers: state.config.headers,
        queryStringParameters: {
          endpoint: `/${state.user.ClientName}/appointments/appointment/delete`,
          appointmentID: id
        }
      };
      return API.del(apiName, path, myInit)
    },
    
    getPersonInfo({ state }, id) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/get';
      const myInit = {
        headers: state.config.headers,
        queryStringParameters: {
          endpoint: `/${state.user.ClientName}/contacts/people/${id}`
        }
      };
      return API.get(apiName, path, myInit)
        .then(({ data }) => data)
    },
    
    addPropertyFeedback({ state }, payload) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/put';
      const myInit = {
        headers: state.config.headers,
        queryStringParameters: {
          endpoint: `/${state.user.ClientName}/appointments/${payload.appointmentID}/feedback/${payload.propertyID}/add`,
          feedback: payload.feedbackText
        }
      };
      return API.put(apiName, path, myInit)
    },
    
    salesInstructionsDetails({ state }, OID) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/get';
      const myInit = {
        headers: state.config.headers,
        queryStringParameters: {
          endpoint: `/${state.user.ClientName}/sales/instructions/${OID}`
        }
      };
      return API.get(apiName, path, myInit)
        .then(({ data }) => data)
    },
    
    lettingPropertyDetails({ state }, OID) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/get';
      const myInit = {
        headers: state.config.headers,
        queryStringParameters: {
          endpoint: `/${state.user.ClientName}/properties/${OID}`
        }
      };
      return API.get(apiName, path, myInit)
        .then(({ data }) => data)
    },
    
    propertyTenanciesLatest({ state }, OID) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/get';
      const myInit = {
        headers: state.config.headers,
        queryStringParameters: {
          endpoint: `/${state.user.ClientName}/properties/${OID}/tenancies/latest`
        }
      };
      return API.get(apiName, path, myInit)
        .then(({ data }) => data)
    },getBuyerDetails({ state }, OID) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/get';
      const myInit = {
        headers: state.config.headers,
        queryStringParameters: {
          endpoint: `/${state.user.ClientName}/contacts/people/buyers/${OID}`
        }
      };
      return API.get(apiName, path, myInit)
        .then(({ data }) => data)
    },
    
    getTenantDetails({ state }, OID) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/get';
      const myInit = {
        headers: state.config.headers,
        queryStringParameters: {
          endpoint: `/${state.user.ClientName}/contacts/people/applicants/${OID}`
        }
      };
      return API.get(apiName, path, myInit)
        .then(({ data }) => data)
    },
    
    myTenancies({ state }, payload) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/get';
      const myInit = {
        headers: state.config.headers,
        queryStringParameters: {
          endpoint: `/${state.user.ClientName}/properties/tenancies/${payload.offset}/${payload.count}`,
          minPrice: payload.minPrice,
          maxPrice: payload.maxPrice,
          minBeds: payload.minBeds,
          maxBeds: payload.maxBeds,
          propertyTypes: payload.propertyTypes
        }
      };
      return API.get(apiName, path, myInit)
        .then(({ data }) => data)
    },
    
    myTenanciesPropertyTypes({ state, commit }) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/get';
      const myInit = {
        headers: state.config.headers,
        queryStringParameters: {
          endpoint: `/${state.user.ClientName}/properties/types`
        }
      };
      return API.get(apiName, path, myInit)
        .then(({ data }) => {
          commit('SET_PROPERTY_TYPES', data)
        })
    },
    
    mySales({ state }, payload) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/get';
      const myInit = {
        headers: state.config.headers,
        queryStringParameters: {
          endpoint: `/${state.user.ClientName}/sales/instructions/${payload.offset}/${payload.count}`,
          minPrice: payload.minPrice,
          maxPrice: payload.maxPrice,
          minBeds: payload.minBeds,
          maxBeds: payload.maxBeds,
          propertyTypes: payload.propertyTypes
        }
      };
      return API.get(apiName, path, myInit)
        .then(({ data }) => data)
    },
    
    getPropertyFeedback({ state }, payload) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/get';
      const myInit = {
        headers: state.config.headers,
        queryStringParameters: {
          endpoint: `/${state.user.ClientName}/appointments/${payload.appointmentOID}/feedback/${payload.propertyOID}`
        }
      };
      return API.get(apiName, path, myInit)
        .then(({ data }) => data)
    },
    
    changePropertyType({ commit }, type) {
      commit('SET_PROPERTY_TYPE', type);
      return '';
    },
      getAllStaffEmails({ commit, dispatch }) {
        return Promise.allSettled([
          dispatch('Messages/getStaffEmails'),
          dispatch('Messages/getCompletedStaffEmails')
        ])
          .then((results) => {
            const staff = {
              data: results[0].status === 'fulfilled' ? results[0].value : {
                Data: [],
                Total: 0,
                Unread: 0,
                SentMessages: []
              }
            };
            const completed = {
              data: results[1].status === 'fulfilled' ? results[1].value : {
                Data: [],
                Total: 0
              }
            };
    
            commit('SET_STAFF_EMAILS', {
              staff,
              completed
            });
            
            return { staff, completed };
          })
          .catch(error => {
            console.error('Error fetching staff emails:', error);
            return {
              staff: {
                data: {
                  Data: [],
                  Total: 0,
                  Unread: 0,
                  SentMessages: []
                }
              },
              completed: {
                data: {
                  Data: [],
                  Total: 0
                }
              }
            };
          });
      },
    
      getAllBranchEmails({ commit, dispatch }) {
        return Promise.allSettled([
          dispatch('Messages/getBranchEmails'),
          dispatch('Messages/getCompletedBranchEmails')
        ])
          .then((results) => {
            const branch = {
              data: results[0].status === 'fulfilled' ? results[0].value : {
                Data: [],
                Total: 0,
                Unread: 0,
                SentMessages: []
              }
            };
            const completed = {
              data: results[1].status === 'fulfilled' ? results[1].value : {
                Data: [],
                Total: 0
              }
            };
    
            commit('SET_BRANCH_EMAILS', {
              branch,
              completed
            });
            
            return { branch, completed };
          })
          .catch(error => {
            console.error('Error fetching branch emails:', error);
            return {
              branch: {
                data: {
                  Data: [],
                  Total: 0,
                  Unread: 0,
                  SentMessages: []
                }
              },
              completed: {
                data: {
                  Data: [],
                  Total: 0
                }
              }
            };
          });
      },
    
      getAllOnlineApplications({ commit, dispatch }) {
        return Promise.allSettled([
          dispatch('Messages/getStaffOnlineApplications'),
          dispatch('Messages/getCompletedStaffOnlineApplications'),
          dispatch('Messages/getBranchOnlineApplications'),
          dispatch('Messages/getCompletedBranchOnlineApplications')
        ])
          .then((results) => {
            const staff = {
              data: results[0].status === 'fulfilled' ? results[0].value : {
                Data: [],
                Count: 0
              }
            };
            const staffCompleted = {
              data: results[1].status === 'fulfilled' ? results[1].value : {
                Data: [],
                Count: 0
              }
            };
            const branch = {
              data: results[2].status === 'fulfilled' ? results[2].value : {
                Data: [],
                Count: 0
              }
            };
            const branchCompleted = {
              data: results[3].status === 'fulfilled' ? results[3].value : {
                Data: [],
                Count: 0
              }
            };
    
            commit('SET_ONLINE_APPLICATIONS', {
              staff,
              staffCompleted,
              branch,
              branchCompleted
            });
            
            return { staff, staffCompleted, branch, branchCompleted };
          })
          .catch(error => {
            console.error('Error fetching online applications:', error);
            return {
              staff: { data: { Data: [], Count: 0 } },
              staffCompleted: { data: { Data: [], Count: 0 } },
              branch: { data: { Data: [], Count: 0 } },
              branchCompleted: { data: { Data: [], Count: 0 } }
            };
          });
      },
    
      getAllLoginRegistrations({ commit, dispatch }) {
        return Promise.allSettled([
          dispatch('Messages/getStaffOnlineLogins'),
          dispatch('Messages/getCompletedStaffOnlineLogins'),
          dispatch('Messages/getBranchOnlineLogins'),
          dispatch('Messages/getCompletedBranchOnlineLogins')
        ])
          .then((results) => {
            const staff = {
              data: results[0].status === 'fulfilled' ? results[0].value : {
                Data: [],
                Count: 0
              }
            };
            const staffCompleted = {
              data: results[1].status === 'fulfilled' ? results[1].value : {
                Data: [],
                Count: 0
              }
            };
            const branch = {
              data: results[2].status === 'fulfilled' ? results[2].value : {
                Data: [],
                Count: 0
              }
            };
            const branchCompleted = {
              data: results[3].status === 'fulfilled' ? results[3].value : {
                Data: [],
                Count: 0
              }
            };
    
            commit('SET_LOGIN_REGISTRATIONS', {
              staff,
              staffCompleted,
              branch,
              branchCompleted
            });
            
            return { staff, staffCompleted, branch, branchCompleted };
          })
          .catch(error => {
            console.error('Error fetching login registrations:', error);
            return {
              staff: { data: { Data: [], Count: 0 } },
              staffCompleted: { data: { Data: [], Count: 0 } },
              branch: { data: { Data: [], Count: 0 } },
              branchCompleted: { data: { Data: [], Count: 0 } }
            };
          });
      },
    
      getAllSalesProperties({ commit, dispatch }) {
        return Promise.allSettled([
          dispatch('Messages/getStaffSalesProperties'),
          dispatch('Messages/getCompletedStaffSalesProperties'),
          dispatch('Messages/getBranchSalesProperties'),
          dispatch('Messages/getCompletedBranchSalesProperties')
        ])
          .then((results) => {
            const staff = {
              data: results[0].status === 'fulfilled' ? results[0].value : {
                Data: [],
                Count: 0
              }
            };
            const staffCompleted = {
              data: results[1].status === 'fulfilled' ? results[1].value : {
                Data: [],
                Count: 0
              }
            };
            const branch = {
              data: results[2].status === 'fulfilled' ? results[2].value : {
                Data: [],
                Count: 0
              }
            };
            const branchCompleted = {
              data: results[3].status === 'fulfilled' ? results[3].value : {
                Data: [],
                Count: 0
              }
            };
    
            commit('SET_SALES_PROPERTIES', {
              staff,
              staffCompleted,
              branch,
              branchCompleted
            });
            
            return { staff, staffCompleted, branch, branchCompleted };
          })
          .catch(error => {
            console.error('Error fetching sales properties:', error);
            return {
              staff: { data: { Data: [], Count: 0 } },
              staffCompleted: { data: { Data: [], Count: 0 } },
              branch: { data: { Data: [], Count: 0 } },
              branchCompleted: { data: { Data: [], Count: 0 } }
            };
          });
      },
    
      getAllLettingsProperties({ commit, dispatch }) {
        return Promise.allSettled([
          dispatch('Messages/getStaffLettingsProperties'),
          dispatch('Messages/getCompletedStaffLettingsProperties'),
          dispatch('Messages/getBranchLettingsProperties'),
          dispatch('Messages/getCompletedBranchLettingsProperties')
        ])
          .then((results) => {
            const staff = {
              data: results[0].status === 'fulfilled' ? results[0].value : {
                Data: [],
                Count: 0
              }
            };
            const staffCompleted = {
              data: results[1].status === 'fulfilled' ? results[1].value : {
                Data: [],
                Count: 0
              }
            };
            const branch = {
              data: results[2].status === 'fulfilled' ? results[2].value : {
                Data: [],
                Count: 0
              }
            };
            const branchCompleted = {
              data: results[3].status === 'fulfilled' ? results[3].value : {
                Data: [],
                Count: 0
              }
            };
    
            commit('SET_LETTINGS_PROPERTIES', {
              staff,
              staffCompleted,
              branch,
              branchCompleted
            });
            
            return { staff, staffCompleted, branch, branchCompleted };
          })
          .catch(error => {
            console.error('Error fetching lettings properties:', error);
            return {
              staff: { data: { Data: [], Count: 0 } },
              staffCompleted: { data: { Data: [], Count: 0 } },
              branch: { data: { Data: [], Count: 0 } },
              branchCompleted: { data: { Data: [], Count: 0 } }
            };
          });
      },
    
    downloadMessageAttachment({ state }, attachmentID) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/get';
      const myInit = {
        headers: state.config.headers,
        queryStringParameters: {
          endpoint: `/${state.user.ClientName}/intray/download/attachment/${attachmentID}`
        },
        response: true // This enables binary response handling in Amplify
      };
      return API.get(apiName, path, myInit)
        .then(({ data }) => data)
    },
  },
  getters: {
    filterTodaysAppointments: (state) => {
      return state.appointments.filter((app) => {
        return (
          app.start.substr(0, 10) == state.today.toISOString().substr(0, 10)
        )
      })
    }
  },
})

import { API } from 'aws-amplify';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
actions: {
  //Staff msgs
  getStaffEmails({ rootState }) {
    const apiName = 'negAppRestApi';
    const path = '/proxy/get';
    const myInit = {
      headers: rootState.config.headers,
      queryStringParameters: {
        endpoint: `/${rootState.user.ClientName}/intray/emails/staff/0/100`
      }
    };
    return API.get(apiName, path, myInit)
      .then(({ data }) => data)
  },

  getCompletedStaffEmails({ rootState }) {
    const apiName = 'negAppRestApi';
    const path = '/proxy/get';
    const myInit = {
      headers: rootState.config.headers,
      queryStringParameters: {
        endpoint: `/${rootState.user.ClientName}/intray/emails/staff/completed/0/100`
      }
    };
    return API.get(apiName, path, myInit)
      .then(({ data }) => data)
  },

  getSentMessages({ commit, rootState }) {
    const apiName = 'negAppRestApi';
    const path = '/proxy/get';
    const myInit = {
      headers: rootState.config.headers,
      queryStringParameters: {
        endpoint: `/${rootState.user.ClientName}/intray/messages/staff/sent/0/100`
      }
    };
    return API.get(apiName, path, myInit)
      .then(({ data }) => {
        commit('SET_SENT_MESSAGES', data, {root: true})
      })
  },

  getBranchEmails({ rootState }) {
    const apiName = 'negAppRestApi';
    const path = '/proxy/get';
    const myInit = {
      headers: rootState.config.headers,
      queryStringParameters: {
        endpoint: `/${rootState.user.ClientName}/intray/emails/branch/0/100`
      }
    };
    return API.get(apiName, path, myInit)
      .then(({ data }) => data)
  },
  getCompletedBranchEmails({ rootState }) {
    const apiName = 'negAppRestApi';
    const path = '/proxy/get';
    const myInit = {
      headers: rootState.config.headers,
      queryStringParameters: {
        endpoint: `/${rootState.user.ClientName}/intray/emails/branch/completed/0/100`
      }
    };
    return API.get(apiName, path, myInit)
      .then(({ data }) => data)
  },

  getBranchSms({ rootState, commit }) {
    const apiName = 'negAppRestApi';
    const path = '/proxy/get';
    const myInit = {
      headers: rootState.config.headers,
      queryStringParameters: {
        endpoint: `/${rootState.user.ClientName}/intray/sms/branch/0/100`
      }
    };
    return API.get(apiName, path, myInit)
      .then(({ data }) => {
        commit('SET_BRANCH_SMS', data, {root: true})
      })
  },

  // Toggle read/unread multiple msgs
  toggleMessageIsRead({ rootState }, payload) {
    const apiName = 'negAppRestApi';
    const path = '/proxy/post';
    const myInit = {
      headers: rootState.config.headers,
      queryStringParameters: {
        endpoint: `/${rootState.user.ClientName}/intray/read`,
        messageIDs: payload
      }
    };
    return API.post(apiName, path, myInit)
  },

  archiveMultipleMessages({ rootState }, payload) {
    const apiName = 'negAppRestApi';
    const path = '/proxy/post';
    const myInit = {
      headers: rootState.config.headers,
      queryStringParameters: {
        endpoint: `/${rootState.user.ClientName}/intray/archive`,
        messageIDs: payload
      }
    };
    return API.post(apiName, path, myInit)
  },

  completeMultipleMessages({ rootState }, payload) {
    const apiName = 'negAppRestApi';
    const path = '/proxy/post';
    const myInit = {
      headers: rootState.config.headers,
      queryStringParameters: {
        endpoint: `/${rootState.user.ClientName}/intray/complete`,
        messageIDs: payload
      }
    };
    return API.post(apiName, path, myInit)
  },

  getLoggedInUserEmailAccount({ commit, rootState }) {
    const apiName = 'negAppRestApi';
    const path = '/proxy/get';
    const myInit = {
      headers: rootState.config.headers,
      queryStringParameters: {
        endpoint: `/${rootState.user.ClientName}/intray/accounts/email`
      }
    };
    return API.get(apiName, path, myInit)
      .then(({ data }) => {
        commit('SET_LOGGED_IN_USER_EMAIL_ACCOUNTS', data.Data, {root: true})
      })
  },

  sendEmailReply({ rootState }, payload) {
    const apiName = 'negAppRestApi';
    const path = '/proxy/post';
    const myInit = {
      headers: rootState.config.headers,
      queryStringParameters: {
        endpoint: `/${rootState.user.ClientName}/intray/emails/${payload.originalMessageID}/reply`
      },
      body: {
        MessagingEmailAccountID: payload.messagingEmailAccountID,
        Content: payload.content,
        To: payload.to,
        CC: payload.cc,
        BCC: payload.bcc
      }
    };
    return API.post(apiName, path, myInit)
  },

  sendEmail({ rootState }, payload) {
    const apiName = 'negAppRestApi';
    const path = '/proxy/post';
    const myInit = {
      headers: rootState.config.headers,
      queryStringParameters: {
        endpoint: `/${rootState.user.ClientName}/intray/emails/send`
      },
      body: {
        MessagingEmailAccountID: payload.messagingEmailAccountID,
        Subject: payload.subject,
        Content: payload.content,
        To: payload.to,
        CC: payload.cc,
        BCC: payload.bcc
      }
    };
    return API.post(apiName, path, myInit)
  },
    getLoggedInUserSmsAccount({ commit, rootState }) {
  const apiName = 'negAppRestApi';
  const path = '/proxy/get';
  const myInit = {
    headers: rootState.config.headers,
    queryStringParameters: {
      endpoint: `/${rootState.user.ClientName}/intray/accounts/text`
    }
  };
  return API.get(apiName, path, myInit)
    .then(({ data }) => {
      commit('SET_LOGGED_IN_USER_SMS_ACCOUNTS', data.Data, {root: true})
    })
},

sendSmsReply({ rootState }, payload) {
  const apiName = 'negAppRestApi';
  const path = '/proxy/post';
  const myInit = {
    headers: rootState.config.headers,
    queryStringParameters: {
      endpoint: `/${rootState.user.ClientName}/intray/sms/${payload.originalMessageID}/reply`
    },
    body: {
      Content: payload.content,
      TextAccountID: payload.textAccountID
    }
  };
  return API.post(apiName, path, myInit)
},

sendSms({ rootState }, payload) {
  const apiName = 'negAppRestApi';
  const path = '/proxy/post';
  const myInit = {
    headers: rootState.config.headers,
    queryStringParameters: {
      endpoint: `/${rootState.user.ClientName}/intray/sms/send`
    },
    body: {
      Content: payload.content,
      TextAccountID: payload.textAccountID,
      To: payload.to
    }
  };
  return API.post(apiName, path, myInit)
  },
  // Online applications
getStaffOnlineApplications({ rootState }) {
  const apiName = 'negAppRestApi';
  const path = '/proxy/get';
  const myInit = {
    headers: rootState.config.headers,
    queryStringParameters: {
      endpoint: `/${rootState.user.ClientName}/intray/tenancyapplications/staff/0/100`
    }
  };
  return API.get(apiName, path, myInit)
    .then(({ data }) => data)
},

getCompletedStaffOnlineApplications({ rootState }) {
  const apiName = 'negAppRestApi';
  const path = '/proxy/get';
  const myInit = {
    headers: rootState.config.headers,
    queryStringParameters: {
      endpoint: `/${rootState.user.ClientName}/intray/tenancyapplications/staff/completed/0/100`
    }
  };
  return API.get(apiName, path, myInit)
    .then(({ data }) => data)
},

getBranchOnlineApplications({ rootState }) {
  const apiName = 'negAppRestApi';
  const path = '/proxy/get';
  const myInit = {
    headers: rootState.config.headers,
    queryStringParameters: {
      endpoint: `/${rootState.user.ClientName}/intray/tenancyapplications/branch/0/100`
    }
  };
  return API.get(apiName, path, myInit)
    .then(({ data }) => data)
},

getCompletedBranchOnlineApplications({ rootState }) {
  const apiName = 'negAppRestApi';
  const path = '/proxy/get';
  const myInit = {
    headers: rootState.config.headers,
    queryStringParameters: {
      endpoint: `/${rootState.user.ClientName}/intray/tenancyapplications/branch/completed/0/100`
    }
  };
  return API.get(apiName, path, myInit)
    .then(({ data }) => data)
},

// Login registrations
getStaffOnlineLogins({ rootState }) {
  const apiName = 'negAppRestApi';
  const path = '/proxy/get';
  const myInit = {
    headers: rootState.config.headers,
    queryStringParameters: {
      endpoint: `/${rootState.user.ClientName}/intray/onlinelogins/staff/0/100`
    }
  };
  return API.get(apiName, path, myInit)
    .then(({ data }) => data)
},

getCompletedStaffOnlineLogins({ rootState }) {
  const apiName = 'negAppRestApi';
  const path = '/proxy/get';
  const myInit = {
    headers: rootState.config.headers,
    queryStringParameters: {
      endpoint: `/${rootState.user.ClientName}/intray/onlinelogins/staff/completed/0/100`
    }
  };
  return API.get(apiName, path, myInit)
    .then(({ data }) => data)
},

getBranchOnlineLogins({ rootState }) {
  const apiName = 'negAppRestApi';
  const path = '/proxy/get';
  const myInit = {
    headers: rootState.config.headers,
    queryStringParameters: {
      endpoint: `/${rootState.user.ClientName}/intray/onlinelogins/branch/0/100`
    }
  };
  return API.get(apiName, path, myInit)
    .then(({ data }) => data)
},

getCompletedBranchOnlineLogins({ rootState }) {
  const apiName = 'negAppRestApi';
  const path = '/proxy/get';
  const myInit = {
    headers: rootState.config.headers,
    queryStringParameters: {
      endpoint: `/${rootState.user.ClientName}/intray/onlinelogins/branch/completed/0/100`
    }
  };
  return API.get(apiName, path, myInit)
    .then(({ data }) => data)
},

// Sales valuations (properties)
getStaffSalesProperties({ rootState }) {
  const apiName = 'negAppRestApi';
  const path = '/proxy/get';
  const myInit = {
    headers: rootState.config.headers,
    queryStringParameters: {
      endpoint: `/${rootState.user.ClientName}/intray/salesrequests/staff/0/100`
    }
  };
  return API.get(apiName, path, myInit)
    .then(({ data }) => data)
},

getCompletedStaffSalesProperties({ rootState }) {
  const apiName = 'negAppRestApi';
  const path = '/proxy/get';
  const myInit = {
    headers: rootState.config.headers,
    queryStringParameters: {
      endpoint: `/${rootState.user.ClientName}/intray/salesrequests/staff/completed/0/100`
    }
  };
  return API.get(apiName, path, myInit)
    .then(({ data }) => data)
},

getBranchSalesProperties({ rootState }) {
  const apiName = 'negAppRestApi';
  const path = '/proxy/get';
  const myInit = {
    headers: rootState.config.headers,
    queryStringParameters: {
      endpoint: `/${rootState.user.ClientName}/intray/salesrequests/branch/0/100`
    }
  };
  return API.get(apiName, path, myInit)
    .then(({ data }) => data)
},

getCompletedBranchSalesProperties({ rootState }) {
  const apiName = 'negAppRestApi';
  const path = '/proxy/get';
  const myInit = {
    headers: rootState.config.headers,
    queryStringParameters: {
      endpoint: `/${rootState.user.ClientName}/intray/salesrequests/branch/completed/0/100`
    }
  };
  return API.get(apiName, path, myInit)
    .then(({ data }) => data)
},

// Lettings valuations (properties)
getStaffLettingsProperties({ rootState }) {
  const apiName = 'negAppRestApi';
  const path = '/proxy/get';
  const myInit = {
    headers: rootState.config.headers,
    queryStringParameters: {
      endpoint: `/${rootState.user.ClientName}/intray/tenancyrequests/staff/0/100`
    }
  };
  return API.get(apiName, path, myInit)
    .then(({ data }) => data)
},

getCompletedStaffLettingsProperties({ rootState }) {
  const apiName = 'negAppRestApi';
  const path = '/proxy/get';
  const myInit = {
    headers: rootState.config.headers,
    queryStringParameters: {
      endpoint: `/${rootState.user.ClientName}/intray/tenancyrequests/staff/completed/0/100`
    }
  };
  return API.get(apiName, path, myInit)
    .then(({ data }) => data)
},

getBranchLettingsProperties({ rootState }) {
  const apiName = 'negAppRestApi';
  const path = '/proxy/get';
  const myInit = {
    headers: rootState.config.headers,
    queryStringParameters: {
      endpoint: `/${rootState.user.ClientName}/intray/tenancyrequests/branch/0/100`
    }
  };
  return API.get(apiName, path, myInit)
    .then(({ data }) => data)
},

getCompletedBranchLettingsProperties({ rootState }) {
  const apiName = 'negAppRestApi';
  const path = '/proxy/get';
  const myInit = {
    headers: rootState.config.headers,
    queryStringParameters: {
      endpoint: `/${rootState.user.ClientName}/intray/tenancyrequests/branch/completed/0/100`
    }
  };
  return API.get(apiName, path, myInit)
    .then(({ data }) => data)
  },
  },
  getters: {}
}
<template>
  <!-- Child of MobileAgenda  -->
  <div>
    <v-card class="">
      <v-card-title class="secondary white--text">
        <v-container class="py-0">
          <v-row>
            <v-col cols="9">
              <p class="headline mb-0 font-weight-light">Create appointment</p>
            </v-col>
            <v-col class="text-right" cols="3">
              <v-btn @click="closeCreateComponent" small text color="white">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>

      <v-container>
        <v-form
          @submit.prevent="createAppointment"
          lazy-validation
          v-model="valid"
          ref="form"
        >
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                :items="appointmentTypes"
                item-text="Name"
                item-value="OID"
                label="Appointment type"
                v-model="appointmentType"
                :rules="[(v) => !!v || 'Item is required']"
                required
                prepend-icon="mdi-playlist-check"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="subject"
                type="text"
                label="Subject"
                required
                prepend-icon="mdi-text-subject"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Date selectors -->
          <v-row>
            <v-col cols="6">
              <v-text-field
                :value="formattedStartDate"
                label="Start"
                readonly
                :rules="[(v) => !!v || 'Start date is required']"
                required
                prepend-icon="mdi-calendar-month-outline"
                @click="dateDialog = true"
              ></v-text-field>
              <v-dialog
                v-model="dateDialog"
                persistent
                :overlay="false"
                max-width="300px"
                transition="dialog-transition"
              >
                <v-date-picker v-model="date" @input="setDates"></v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :value="formattedEndDate"
                label="End"
                readonly
                :rules="[(v) => !!v || 'End date is required']"
                required
                prepend-icon="mdi-calendar-month-outline"
                @click="endDateDialog = true"
              ></v-text-field>
              <v-dialog
                v-model="endDateDialog"
                persistent
                :overlay="false"
                max-width="300px"
                transition="dialog-transition"
              >
                <v-date-picker
                  v-model="endDate"
                  @input="endDateDialog = false"
                ></v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>

          <!-- TIME SELECTORS -->
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="startTime"
                label="Start"
                readonly
                :rules="[(v) => !!v || 'Start time is required']"
                required
                prepend-icon="mdi-clock-outline"
                @click="startTimeDialog = true"
              ></v-text-field>
              <v-dialog
                v-model="startTimeDialog"
                persistent
                :overlay="false"
                max-width="300px"
                transition="dialog-transition"
              >
                <v-time-picker format="24hr" v-model="startTime" full-width>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="startTimeDialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn text color="primary" @click="setTimes()">OK</v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="endTime"
                label="End"
                readonly
                :rules="[(v) => !!v || 'End time is required']"
                required
                prepend-icon="mdi-clock-outline"
                @click="endTimeDialog = true"
              ></v-text-field>
              <v-dialog
                v-model="endTimeDialog"
                persistent
                :overlay="false"
                max-width="300px"
                transition="dialog-transition"
              >
                <v-time-picker format="24hr" v-model="endTime" full-width>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="endTimeDialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn text color="primary" @click="endTimeDialog = false"
                    >OK</v-btn
                  >
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>

          <v-row>
            <!-- Search Property Component -->
            <v-col cols="12" sm="6">
              <SearchProperty
                color="blue"
                @selectedProperties="showProperties"
              />

              <div v-for="property in selectedProperties" :key="property.OID">
                <v-chip
                  label
                  block
                  class="ma-2"
                  close
                  @click:close="removeProperty(property)"
                  color="blue lighten-3"
                  text-color="white"
                >
                  <span
                    class="d-inline-block text-truncate"
                    style="max-width: 250px"
                  >
                    {{ property.Subject }}
                  </span>
                </v-chip>
              </div>
            </v-col>

            <!-- Search Person Component -->
            <v-col cols="12" sm="6">
              <SearchPerson color="green" @selectedPersons="showPersons" />

              <div v-for="person in selectedPersons" :key="person.OID">
                <v-chip
                  label
                  block
                  class="ma-2"
                  close
                  @click:close="removePerson(person)"
                  color="blue lighten-3"
                  text-color="white"
                >
                  <span
                    class="d-inline-block text-truncate"
                    style="max-width: 250px"
                  >
                    {{ person.Subject }}
                  </span>
                </v-chip>
              </div>
            </v-col>
          </v-row>

          <!-- Textarea -->
          <v-row>
            <v-col cols="12" sm="6">
              <v-textarea
                class="mt-3"
                name="input-7-1"
                label="Comments"
                value=""
                v-model="comments"
                height="50"
                prepend-icon="mdi-message-outline"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                filled
                background-color="primary"
                dark
                class="mt-sm-5"
                :items="$store.state.Calendar.allDiaries"
                return-object
                item-text="StaffName"
                v-model="assignedTo"
                label="Book appointment to:"
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="mt-n6 mb-12">
            <v-col cols="12" sm="6">
              <v-row>
                <v-col cols="6" sm="10">
                  <v-btn
                    width="175"
                    height="42"
                    :loading="creatingAppointment"
                    block
                    :disabled="!valid"
                    type="submit"
                    @click="validate"
                    color="success"
                    >Save</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

# Previous template section remains exactly the same until the script tag

<script>
import { mapState } from "vuex";
import SearchProperty from "@/components/Searches/SearchProperty";
import SearchPerson from "@/components/Searches/SearchPerson";
import moment from "moment/src/moment";
import { API } from '@aws-amplify/api';

export default {
  name: "create-appointment",
  props: [
    "selectedStartTime",
    "selectedEndTime",
    "selectedStartDate",
    "selectedPersonProp",
  ],
  components: {
    SearchProperty,
    SearchPerson,
  },
  data: () => ({
    creatingAppointment: false,
    startTimeDialog: false,
    endTimeDialog: false,
    dateDialog: false,
    endDateDialog: false,
    valid: true,
    subject: "",
    allDay: false,
    date: "",
    endDate: "",
    startTime: "08:00",
    endTime: "09:00",
    appointmentType: "0304-f421-2742-8afa",
    comments: "",
    selectedProperties: [],
    selectedPersons: [],
    assignedTo: "", // appointment assigned to
    snackbar: false
  }),
  created() {
    this.assignedTo = this.$store.state.Calendar.allDiaries[0]; // assigning signed in user diary

    // check for linked properties from properties/book in the $store and add to selectedProperties if there are
    if (this.$store.state.Calendar.propertyToBook) {
      this.selectedProperties.push(this.$store.state.Calendar.propertyToBook);
    }

    if (this.selectedStartTime) {
      this.startTime = this.selectedStartTime;
      this.endTime = this.selectedEndTime;
      this.date = this.selectedStartDate;
      this.endDate = this.selectedStartDate;
    }
    this.date = this.selectedStartDate;
    this.endDate = this.selectedStartDate;

    if (this.selectedPersonProp) {
      this.selectedPersons.push(this.selectedPersonProp);
    }
  },
  computed: {
    ...mapState(["appointmentTypes"]),
    formattedStartDate() {
      return this.date ? moment(this.date).format("DD/MM/YYYY") : "";
    },
    formattedEndDate() {
      return this.endDate ? moment(this.endDate).format("DD/MM/YYYY") : "";
    },
  },
  methods: {
    closeCreateComponent() {
      this.$emit("closeCreateComponent");
    },
    setDates() {
      this.endDate = this.date;
      this.dateDialog = false;
    },
    setTimes() {
      const durationInMinutes = "60";
      const endTime = moment(this.startTime, "HH:mm")
        .add(durationInMinutes, "minutes")
        .format("HH:mm");
      this.endTime = endTime;
      this.startTimeDialog = false;
    },
    removeProperty(item) {
      const index = this.selectedProperties.indexOf(item);
      if (index > -1) {
        this.selectedProperties.splice(index, 1);
      }
    },
    removePerson(item) {
      const index = this.selectedPersons.indexOf(item);
      if (index > -1) {
        this.selectedPersons.splice(index, 1);
      }
    },
    showProperties(property) {
      if (property) {
        this.selectedProperties.push(property);
      }
    },
    showPersons(persons) {
      if (persons) {
        this.selectedPersons.push(persons);
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
      }
    },
    async createAppointment() {
      this.creatingAppointment = true;
      try {
        // Create appointment through mapped endpoint
        const appointmentPayload = {
          Start: `${this.date}T${this.startTime}`,
          End: `${this.endDate}T${this.endTime}`,
          StaffID: this.assignedTo.StaffID,
          AppointmentType: this.appointmentType,
          Subject: this.subject,
          ExtraComments: this.comments,
        };

        // Map to POST /corporate/agentapp/:shortName/:clientName/appointments/appointment/create
        const response = await API.post('negAppRestApi', '/proxy/post', {
          headers: {
            token: this.$store.state.user.LoginToken
          },
          queryStringParameters: {
            endpoint: `/${this.$store.state.user.ClientName}/appointments/appointment/create`
          },
          body: appointmentPayload
        });

        // Extract appointment ID from the raw response
        const rawResponse = response.data;
        const createdAppointmentOID = rawResponse.substr(13, 19);

        // Link properties if any are selected
        if (this.selectedProperties.length) {
          const propertyIDs = this.selectedProperties.map((x) => x.OID);
          try {
            // Map to PUT /corporate/agentapp/:shortName/:clientName/appointments/:appointmentID/link/properties
            await API.put('negAppRestApi', '/proxy/put', {
              headers: {
                token: this.$store.state.user.LoginToken
              },
              queryStringParameters: {
                endpoint: `/${this.$store.state.user.ClientName}/appointments/${createdAppointmentOID}/link/properties`
              },
              body: propertyIDs
            });
            
            console.log("Properties linked successfully");
          } catch (err) {
            console.error("Error linking properties:", err);
            this.$emit("linkPropertyError", err);
            this.resetDialog();
            return;
          }
        }

        // Link persons if any are selected
        if (this.selectedPersons.length) {
          const personsIDs = this.selectedPersons.map((x) => x.OID);
          try {
            // Map to PUT /corporate/agentapp/:shortName/:clientName/appointments/:appointmentID/link/people
            await API.put('negAppRestApi', '/proxy/put', {
              headers: {
                token: this.$store.state.user.LoginToken
              },
              queryStringParameters: {
                endpoint: `/${this.$store.state.user.ClientName}/appointments/${createdAppointmentOID}/link/people`
              },
              body: personsIDs
            });
            
            console.log("Persons linked successfully");
          } catch (err) {
            console.error("Error linking persons:", err);
            this.$emit("linkPersonError", err);
            this.resetDialog();
            return;
          }
        }

        // Success - reset form and close component
        this.resetDialog();
        this.$emit("appointmentCreated", createdAppointmentOID);
      } catch (error) {
        console.error("Error creating appointment:", error);
        this.creatingAppointment = false;
        this.$emit("createError", error);
      }
    },
    resetDialog() {
      this.creatingAppointment = false;
      this.date = "";
      this.endDate = "";
      this.startTime = "";
      this.endTime = "";
      this.selectedProperties = [];
      this.selectedPersons = [];
      this.subject = "";
      this.comments = "";
      this.$emit("closeCreateComponent");
    }
  }
};
</script>
<template>
  <v-autocomplete
    @update:search-input="isTyping = true"
    @input="chosenProperties"
    @click:clear="clearSearch"
    v-model="model"
    :search-input.sync="searchQuery"
    label="Search Tenancy"
    :items="searchResult"
    item-text="Subject"
    :item-value="itemValue"
    :loading="loading"
    :hide-no-data="hideNoData"
    :no-data-text="noDataText"
    prepend-inner-icon="mdi-home"
    :menu-props="{ offsetY: true }"
    clearable
    return-object
    hint="At least 5 characters"
    :color="color"
    outlined
    dense
  ></v-autocomplete>
</template>

<script>
import debounce from "lodash/debounce";
import { API } from '@aws-amplify/api';

export default {
  name: "search-letting-properties",
  props: {
    color: {
      type: String,
      default: "blue",
    },
  },
  data() {
    return {
      model: null,
      searchQuery: null,
      isTyping: false,
      searchResult: [],
      loading: false,
      hideNoData: true,
      noDataText: "",
    };
  },
  watch: {
    searchQuery: debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value && this.searchQuery && this.searchQuery.length > 5) {
        this.searchProperty(this.searchQuery);
        this.loading = true;
      }
    },
  },
  methods: {
    itemValue(item) {
      if (item) {
        return item.OID;
      }
      return "";
    },
    clearSearch() {
      this.isTyping = false;
      this.searchQuery = "";
    },
    chosenProperties() {
      const selectedProperty = this.model;
      this.$emit("selectedLettingProperty", selectedProperty);
      this.model = null;
    },
    async searchProperty(query) {
      this.loading = true;
      try {
        const response = await API.get('negAppRestApi', '/proxy/get', {
          headers: {
            token: this.$store.state.user.LoginToken
          },
          queryStringParameters: {
            endpoint: `/${this.$store.state.user.ClientName}/search/lettingsproperties`,
            address: query
          }
        });

        const searchData = response.data;
        if (searchData && searchData.Count > 0) {
          this.searchResult = searchData.Data;
          this.hideNoData = true;
        } else {
          this.searchResult = [];
          this.hideNoData = false;
          this.noDataText = "No data found with this name";
        }
      } catch (error) {
        console.error('Lettings property search error:', error);
        this.searchResult = [];
        this.hideNoData = false;
        this.noDataText = "An error has occurred in the system. Please try again.";
        this.$emit('searchError', error);
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>
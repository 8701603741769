import { API } from '@aws-amplify/api'

export default {
  namespaced: true,
  state: {
    selectedBuyer: '',
    selectedApplicant: ''
  },
  mutations: {
    SET_SELECTED_BUYER(state, data) {
      state.selectedBuyer = data
    },
    CLEAR_SELECTED_BUYER(state) {
      state.selectedBuyer = ''
    },
    SET_TENANCY_APPLICANT(state, data) {
      state.selectedApplicant = data
    },
    CLEAR_SELECTED_APPLICANT(state) {
      state.selectedApplicant = ''
    }
  },
  actions: {
    submitSalesOffer({ rootState }, offerObject) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/post';
      const myInit = {
        headers: rootState.config.headers,
        queryStringParameters: {
          endpoint: `/${rootState.user.ClientName}/sales/instructions/offers/submit`
        },
        body: {
          BuyerApplicantID: offerObject.BuyerApplicantID,
          OfferAmount: offerObject.OfferAmount,
          DepositType: offerObject.DepositType,
          FinancialVerified: offerObject.FinancialVerified,
          SubjectToContract: offerObject.SubjectToContract,
          SubjectToMortgage: offerObject.SubjectToMortgage,
          SubjectToSurvey: offerObject.SubjectToSurvey,
          FixturesAndFittings: offerObject.FixturesAndFittings,
          SpecialTerms: offerObject.SpecialTerms,
          InternalNotes: offerObject.InternalNotes,
          Documents: offerObject.Documents
        }
      };
      return API.post(apiName, path, myInit)
        .then(response => response.data)
    },

    createApplicantBuyer({ rootState }, applicantObject) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/post';
      const myInit = {
        headers: rootState.config.headers,
        queryStringParameters: {
          endpoint: `/${rootState.user.ClientName}/sales/applicants/create`
        },
        body: {
          Title: applicantObject.Title,
          Forename: applicantObject.Forename,
          Surname: applicantObject.Surname,
          Email: applicantObject.Email,
          Mobile: applicantObject.Mobile,
          Landline: applicantObject.Landline,
          PreferredContactMethod: applicantObject.PreferredContactMethod,
        }
      };
      return API.post(apiName, path, myInit)
        .then(response => response.data)
    },

    submitTenancyApplication({ rootState }, payload) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/post';
      const myInit = {
        headers: rootState.config.headers,
        queryStringParameters: {
          endpoint: `/${rootState.user.ClientName}/properties/tenancies/applications/submit`,
          tenancyID: payload.tenancyID
        },
        body: {
          Applicant: {
            TenancyRef: payload.Applicant.TenancyRef,
            Title: payload.Applicant.Title,
            Forename: payload.Applicant.Forename,
            Surname: payload.Applicant.Surname,
            DOB: payload.Applicant.DOB,
            Nationality: payload.Applicant.Nationality,
            MobilePhone: payload.Applicant.MobilePhone,
            EmailAddress: payload.Applicant.EmailAddress,
            TenantType: payload.Applicant.TenantType,
            GeneralNotes: payload.Applicant.GeneralNotes,
            Reference: payload.Applicant.Reference,
          },
          Documents: payload.Applicant.Documents
        }
      };
      return API.post(apiName, path, myInit)
        .then(response => response.data)
    }
  }
}
<template>
  <div class="messages mb-12">
    <v-overlay :value="loading">
      <v-progress-circular color="#E66544" indeterminate size="64"></v-progress-circular>
    </v-overlay>
    
    <v-container class="pa-0">
      <transition name="fade-slide-vertical" mode="out-in">
        <div class="sub-container d-flex flex-column align-center ">
          <div  style="width: 100%;" class="px-5 mt-5 d-flex justify-space-between align-center">
            <span class="display-1 font-weight-light">Messages</span>
            <v-btn small @click="refreshAllMessages" color="blue" text>
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </div>
          <!-- Messages Dashboard - category icons -->
          <div class="px-2">
            <v-row dense class="mt-12" justify="space-around">
              <v-col 
                @click="selectData(item)" 
                class="rounded category-btn grey lighten-5 ma-1 pa-3 pa-lg-5 pointer d-flex flex-column align-center" 
                v-for="item in messageGroups" :key="item.name" 
                cols="3" sm="3"
              >
                <div
                 class="pa-1 text-center"
                >
                  <div class="badge-wrapper" v-if="returnUnreadMsgs(item.name) > 0">
                    <v-badge  class="mt-3" right :color="item.color">
                      <v-icon large :color="item.color">{{ item.icon }}</v-icon>
                      <span slot="badge">{{ returnUnreadMsgs(item.name) }}</span> 
                    </v-badge>
                    <p class="my-0 text-center caption">{{ item.name }}</p>
                  </div>
                  <div v-else-if="returnUnreadMsgs(item.name) === -1">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                  <div v-else>
                    <v-icon class="mt-3" large :color="item.color">{{ item.icon }}</v-icon>
                    <p class="my-0 text-center caption">{{ item.name }}</p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </transition>
      <!-- Snack bar shows API errors -->
      <v-snackbar
        multi-line
        class="mb-16"
        color="red"
        v-model="snackbar"
      >
        <ul>
          <li v-for="error in errors" :key="error">{{error}}</li>
        </ul>
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>

import {mapState} from 'vuex'

export default {
  name: 'messages',
  data() {
    return {
      loading: false,
      snackbar: false,
      messageGroups: {
       staffEmails: { name: 'My emails', icon: 'mdi-email', color: 'primary', unRead: '' },
       branchEmails: { name: 'Branch emails', icon: 'mdi-email', color: 'primary', unRead: ''},
       branchSms: { name: 'Branch sms', icon: 'mdi-cellphone-basic', color: 'primary', unRead: ''},
       onlineApplication: { name: 'Online applications', icon: 'mdi-file-document-edit-outline', color: 'secondary', unRead: ''},
       loginReg: { name: 'Login registrations', icon: 'mdi-login-variant', color: 'secondary', unRead: ''},
       salesProperties: { name: 'Sales valuations', icon: 'mdi-home-city-outline', color: 'secondary', unRead: ''},
       lettingProperties: { name: 'Lettings valuations', icon: 'mdi-home-city-outline', color: 'secondary', unRead: ''},
      },
      fetchingLatestMessages: null,
      fetchingLatestRequests: null,
      errors: []
    }
  },
  methods: {
    selectData(item) { //click to navigate to components page
      if(item.name === 'My emails') {
        this.$router.push({ name: 'staffEmails'})
      } 
      else if(item.name === 'Branch emails') {
        this.$router.push({ name: 'branchEmails'})
      } 
      else if(item.name === 'Branch sms') {
        this.$router.push({ name: 'branchSms'})
      } 
      else if(item.name === 'Online applications') {
        this.$router.push({ name: 'onlineApplications'})
      }
      else if(item.name === 'Login registrations') {
        this.$router.push({ name: 'registrations'})
      }
      else if(item.name === 'Sales valuations') {
        this.$router.push({name: 'salesValuations'})
      }
      else if(item.name === 'Lettings valuations') {
        this.$router.push({name: 'lettingsValuations'})
      }
    },
    refreshAllMessages() { 
      //hidden btn on dashboard to refresh all calls
      //all dispatches to $store should go here if btn is used
      this.loading = true
      this.$store.dispatch('getAllStaffEmails').then( () => this.loading = false).catch( () => this.loading = false)
      this.$store.dispatch('getAllBranchEmails')
      this.$store.dispatch('Messages/getBranchSms')
      this.$store.dispatch('Messages/getSentMessages')
      this.$store.dispatch('getAllOnlineApplications')
      this.$store.dispatch('getAllLoginRegistrations')
      this.$store.dispatch('getAllSalesProperties')
      this.$store.dispatch('getAllLettingsProperties')
    },
    fetchLatestMessages() { //automatically fetch newest data every 5 mins
     this.fetchingLatestMessages = setInterval( () => {
        this.$store.dispatch('getAllStaffEmails')
        this.$store.dispatch('Messages/getSentMessages')
        this.$store.dispatch('getAllBranchEmails')
        this.$store.dispatch('Messages/getBranchSms')
        console.log('Fetching latest emails & text!')
      }, 300000) //5 minutes = 300000
    },
    fetchLatestRequests() { //automatically fetch newest data every 5 mins
      this.fetchingLatestRequests = setInterval( () => {
        this.$store.dispatch('getAllOnlineApplications')
        this.$store.dispatch('getAllLoginRegistrations')
        this.$store.dispatch('getAllSalesProperties')
        this.$store.dispatch('getAllLettingsProperties')
        console.log('Fetching latest requests (sales, lettings, applications, logins)!')
      }, 900000) // 15 minutes
    },
    returnUnreadMsgs(name) { // depneding on item name returns the unread number of msgs
      if(name === 'My emails') { 
        if(this.$store.state.staffEmails) return this.$store.state.staffEmails.staff.data.Unread 
        else return -1
      }
      if(name === 'Branch emails') { 
        if(this.$store.state.branchEmails) return this.$store.state.branchEmails.branch.data.Unread 
        else return -1  
      }
      if(name === 'Branch sms') { 
        if(this.$store.state.branchSms) return this.$store.state.branchSms.Unread 
        else return -1  
      }      
      if(name === 'Online applications') { 
        if(this.$store.state.onlineApplications)  {
          return this.$store.state.onlineApplications.branch.data.Count + this.$store.state.onlineApplications.staff.data.Count
        }
        else return -1  
      }      
      if(name === 'Login registrations') { 
        if(this.$store.state.loginRegistrations) {
          return this.$store.state.loginRegistrations.branch.data.Count + this.$store.state.loginRegistrations.staff.data.Count
        }
        else return -1  
      }      
      if(name === 'Sales valuations') { 
        if(this.$store.state.salesProperties) {
          return this.$store.state.salesProperties.branch.data.Count + this.$store.state.salesProperties.staff.data.Count
        }
        else return -1  
      }      
      if(name === 'Lettings valuations') { 
        if(this.$store.state.lettingsProperties) {
          return this.$store.state.lettingsProperties.branch.data.Count + this.$store.state.lettingsProperties.staff.data.Count 
        }
        else return -1  
      }
      return 0
    },
    setErrorMessage(msg) {
      this.errors.push(msg)
      this.snackbar = true
    },
  },
  computed: {
    ...mapState({
      staffUnread: state => state.staffEmails.data.Unread,
      staffEmails: state => state.staffEmails
    }),
  },
  created() {
    this.$store.commit("EXPIRE_LOGIN");
    if(this.$store.state.user !== ''){
      // Check for actual data rather than just object existence
      if(!this.$store.state.staffEmails.staff.data.Data.length) {
        this.$store.dispatch('getAllStaffEmails')
        .catch(() => {
          this.setErrorMessage('Error: could not fetch your emails')
        })
      }
      if(!this.$store.state.branchEmails.branch.data.Data.length) {
        this.$store.dispatch('getAllBranchEmails')
        .catch(() => {
          this.setErrorMessage('Error: could not fetch branch emails')
        })
      }
      if(!this.$store.state.branchSms.Data.length) {
        this.$store.dispatch('Messages/getBranchSms')
        .catch(() => {
          this.setErrorMessage('Error: could not fetch branch SMS')
        })
      }
      if(!this.$store.state.onlineApplications.staff.data.Data.length) {
        this.$store.dispatch('getAllOnlineApplications')
        .catch(() => {
          this.setErrorMessage('Error: could not fetch online applications')
        })
      }
      if(!this.$store.state.loginRegistrations.staff.data.Data.length) {
        this.$store.dispatch('getAllLoginRegistrations')
        .catch(() => {
          this.setErrorMessage('Error: could not fetch login registrations')
        })
      }
      if(!this.$store.state.sentMessages.Data.length) {
        this.$store.dispatch('Messages/getSentMessages')
        .catch(() => {
          this.setErrorMessage('Error: could not fetch sent messages')
        })
      }
      if(!this.$store.state.salesProperties.staff.data.Data.length) {
        this.$store.dispatch('getAllSalesProperties')
        .catch(() => {
          this.setErrorMessage('Error: could not fetch sales properties')
        })
      }
      if(!this.$store.state.lettingsProperties.staff.data.Data.length) {
        this.$store.dispatch('getAllLettingsProperties')
        .catch(() => {
          this.setErrorMessage('Error: could not fetch letting properties')
        })
      }
      if(!this.$store.state.loggedInUserSmsAccounts.Data.length) {
        this.$store.dispatch('Messages/getLoggedInUserSmsAccount')
        .catch(() => {
          this.setErrorMessage('Error: could not fetch your SMS accounts')
        })
      }
      if(!this.$store.state.loggedInUserEmailAccounts.Data.length) {
        this.$store.dispatch('Messages/getLoggedInUserEmailAccount')
        .catch(() => {
          this.setErrorMessage('Error: could not fetch your email accounts')
        })
      }

      this.fetchLatestMessages()
      this.fetchLatestRequests()
    }
    else{
      this.$router.push('/');
    }
  },
  beforeDestroy() {
    clearInterval(this.fetchingLatestMessages)
    clearInterval(this.fetchingLatestRequests)
  },
  watch: {
    staffEmails(val) {
      if(!val) { this.loading = true }
      else { this.loading = false }
    }
  }
}
</script>

<style>
.v-item-group {
  margin: 0 0 !important;
}
/* #sticky-nav {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  width: 100%;
  z-index: 1 !important;
  border-bottom: 1px solid rgba(242, 101, 66, 0.37);
} */
.messages-outer-div {
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .category-btn {
    border: 1px solid rgba(242, 101, 66, 0.2);
    margin: 5px;
    border-radius: 8px;
    transition: all ease .3s;
  }

  .category-btn:hover {
    background-color: rgba(242, 101, 66, 0.1);

  }
}
</style>
import { API } from 'aws-amplify'

export default {
  namespaced: true,
  state: {
    allDiaries: [],
    propertyToBook: ''
  },
  mutations: {
    SET_ALL_DIARIES(state, data) {
      state.allDiaries = data
    },
    ADD_MY_DIARY(state, data) {
      state.allDiaries.unshift(data)
    },
    SET_PROPERTY_TO_BOOK(state, data) {
      state.propertyToBook = data
    },
    CLEAR_PROPERTY_TO_BOOK(state) {
      state.propertyToBook = ''
    }
  },
  actions: {
    // Get all diaries
    listAllDiaries({ rootState, commit }) {
      const apiName = 'negAppRestApi';
      const path = '/proxy/get';
      const myInit = {
        headers: rootState.config.headers,
        queryStringParameters: {
          endpoint: `/${rootState.user.ClientName}/diary/diaries`
        }
      };

      return API.get(apiName, path, myInit)
      .then((response) => {
        // Extract the data property from the Lambda response
        const rawData = response.data;
        // Ensure rawData is an array
        const diariesArray = Array.isArray(rawData) ? rawData : [];
        commit('SET_ALL_DIARIES', diariesArray);
        // ADD LOGGED IN USER DIARY TO LIST
        const myDiary = {
          DiaryName: '',
          StaffID: rootState.sessionData.OID,
          StaffName: rootState.sessionData.ActiveUser
        };
        commit('ADD_MY_DIARY', myDiary);
      })
        .catch(err => console.log(err));
    },

    getColleaguesDiary({ rootState, commit }, diaryObject) {
      // Setting date range for appointments
      let date = rootState.date; // start date
      date.setDate(date.getDate() - 90); // minus 90 days
      let pastDate = date
        .toISOString()
        .substr(0, 10);

      let date2 = rootState.date2; // end date
      date2.setDate(date2.getDate() + 1095); // plus 1095 days
      let futureDate = date2
        .toISOString()
        .substr(0, 10);

      const apiName = 'negAppRestApi';
      const path = '/proxy/get';
      const myInit = {
        headers: rootState.config.headers,
        queryStringParameters: {
          endpoint: `/${rootState.user.ClientName}/diary/appointments`,
          startDate: pastDate,
          endDate: futureDate,
          diaryName: diaryObject.DiaryName
        }
      };

      return API.get(apiName, path, myInit)
        .then((response) => {
          // Extract the data property from the Lambda response
          const rawData = response.data;
          let newArray = rawData.map((obj) => {
            return {
              ...obj,
              end: obj.End,
              start: obj.Start,
              name: obj.Subject,
              myCalendar: false, // setting appointments as colleague's
              colleagueName: diaryObject.StaffName,
              color: diaryObject.color
            }
          });
          commit('SET_COLLEAGUES_APPOINTMENTS', newArray, { root: true });
        });
    }
  }
}
<template>
  <!-- Component is imported into the PropertyCard in the Properties page -->
  <div>
    <v-card class>
      <v-card-title class="secondary white--text py-0">
        <v-container class="py-0">
          <v-row>
            <v-col cols="9">
              <p class="headline mb-0 font-weight-light">Book appointment</p>
            </v-col>
            <v-col class="text-right" cols="3">
              <v-btn @click="closeBookDialog" small text color="white">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-container>
        <v-card solo flat class="pa-5">
          <v-form ref="form" v-model="valid">
            <TheCalendar />
          </v-form>
        </v-card>
      </v-container>
    </v-card>

    <!-- Snackbar for validation feedback -->
    <v-snackbar v-model="snackbar" :timeout="3000">
      Form is valid
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from 'moment/src/moment';
import { API } from '@aws-amplify/api';
import TheCalendar from '@/components/TheCalendar.vue';

export default {
  props: {
    Property: {
      type: Object
    },
    PropertyType: {
      type: String
    }
  },
  components: {
    TheCalendar
  },
  data: () => ({
    creatingAppointment: false,
    startTimeDialog: false,
    endTimeDialog: false,
    dateDialog: false,
    endDateDialog: false,
    valid: true,
    snackbar: false,
    subject: "",
    allDay: false,
    date: "",
    endDate: "",
    startTime: "",
    endTime: "",
    // pre populated to Viewing
    appointmentType: "0304-f421-2742-8afa",
    comments: "",
    selectedProperty: "",
    selectedPropertyID: "",
    selectedPropertySubject: "",
    selectedProperties: [], // Array for multiple properties if needed
    selectedPersons: [],
    assignedTo: '', //who appointment is assigned to
  }),
  created() {
    this.assignedTo = this.$store.state.Calendar.allDiaries[0] // assigning signed in user diary 

    let today = new Date();
    today.setDate(today.getDate() + 1);
    let tomorrow = today.toISOString().substr(0, 10);
    this.date = tomorrow;
    this.endDate = tomorrow;

    //setting start and end time
    // start time set top of nearest hour
    const hours = today.getHours()
    const minutes = today.getMinutes()
    if(minutes > 30) {
      const topOfHour = hours + 1
      this.startTime = `${topOfHour}:00`
      const topOfHourEnd = topOfHour + 1 
      this.endTime = `${topOfHourEnd}:00`

    } else {
      this.startTime = `${hours}:00` 
      const end = hours + 1
      this.endTime = `${end}:00`
    }
  },
  computed: {
    ...mapState(["appointmentTypes"]),
    formattedStartDate() {
      return this.date ? moment(this.date).format("DD/MM/YYYY") : "";
    },
    formattedEndDate() {
      return this.endDate ? moment(this.endDate).format("DD/MM/YYYY") : "";
    }
  },
  methods: {
    closeBookDialog() {
      this.$emit("closeBookDialog", null);
    },
    setDates() {
      this.endDate = this.date;
      this.dateDialog = false;
    },
    setTimes() {
      const durationInMinutes = "60";
      const endTime = moment(this.startTime, "HH:mm")
        .add(durationInMinutes, "minutes")
        .format("HH:mm");
      this.endTime = endTime;
      this.startTimeDialog = false;
    },
    removeProperty(item) {
      const index = this.selectedProperties.indexOf(item);
      if (index > -1) {
        this.selectedProperties.splice(index, 1);
      }
    },
    removePerson(item) {
      const index = this.selectedPersons.indexOf(item);
      if (index > -1) {
        this.selectedPersons.splice(index, 1);
      }
    },
    showProperties(property) {
      if (property) {
        this.selectedProperties.push(property);
      }
    },
    showPersons(persons) {
      if (persons) {
        this.selectedPersons.push(persons);
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
      }
    },
    async createAppointment() {
      this.creatingAppointment = true;
      try {
        const appointmentPayload = {
          Start: `${this.date}T${this.startTime}`,
          End: `${this.endDate}T${this.endTime}`,
          StaffID: this.assignedTo.StaffID,
          AppointmentType: this.appointmentType,
          Subject: this.subject,
          ExtraComments: this.comments
        };

        // Create appointment
        const appointmentResponse = await API.post('negAppRestApi', '/proxy/post', {
          headers: {
            token: this.$store.state.user.LoginToken
          },
          queryStringParameters: {
            endpoint: `/${this.$store.state.user.ClientName}/appointments/appointment/create`
          },
          body: appointmentPayload
        });

        // Extract the appointment ID from the raw response data
        const createdAppointmentOID = appointmentResponse.data.substr(13, 19);
        console.log("appointment created: " + createdAppointmentOID);

        let propertyIDs = [];
        propertyIDs.push(this.selectedPropertyID);

        // Link properties
        await API.put('negAppRestApi', '/proxy/put', {
          headers: {
            token: this.$store.state.user.LoginToken
          },
          queryStringParameters: {
            endpoint: `/${this.$store.state.user.ClientName}/appointments/${createdAppointmentOID}/link/properties`
          },
          body: propertyIDs
        });
        
        console.log("property linked OK");

        // Link persons if any are selected
        if (this.selectedPersons.length) {
          const personsIDs = this.selectedPersons.map(x => x.OID);

          await API.put('negAppRestApi', '/proxy/put', {
            headers: {
              token: this.$store.state.user.LoginToken
            },
            queryStringParameters: {
              endpoint: `/${this.$store.state.user.ClientName}/appointments/${createdAppointmentOID}/link/people`
            },
            body: personsIDs
          });

          console.log("person linked OK");
        }
        
        this.resetDialog();
      } catch (err) {
        this.$emit("linkPersonError", err);
        this.resetDialog();
      }
    },
    resetDialog() {
      this.creatingAppointment = false;
      this.date = "";
      this.endDate = "";
      this.startTime = "";
      this.endTime = "";
      this.selectedProperty = "";
      this.selectedPersons = [];
      this.$store.dispatch("fetchAppointments");
      this.$emit("closeBookDialog", null);
    }
  }
};
</script>